@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icomoon.eot?dvkf19");
  src: url("../assets/fonts/icomoon.eot?dvkf19#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/icomoon.ttf?dvkf19") format("truetype"),
    url("../assets/fonts/icomoon.woff?dvkf19") format("woff"),
    url("../assets/fonts/icomoon.svg?dvkf19#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play-circle:before {
  content: "\e901";
}

.icon-long-arrow-left:before {
  content: "\e900";
}
