@import url("./icons.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .button {
    @apply p-4 py-3 w-full inline-block text-center rounded-2xl font-medium cursor-pointer hover:bg-tertiary transition-all duration-200;
  }
}

:root {
  --header-height: 7rem;
  --footer-height: 4.4rem;
}

.header-height {
  height: var(--header-height);
}

.footer-height {
  height: var(--footer-height);
}

.content-height {
  min-height: calc(100vh - (var(--header-height) + var(--footer-height)));
}

.content-mt {
  margin-top: var(--header-height);
}

.header-shadow {
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.3);
}

.main-text-header {
  font-size: 1.5rem;
}

@media screen and (min-width: 320px) {
  .main-text-header {
    font-size: 1.75rem;
  }
}

@media screen and (min-width: 380px) {
  .main-text-header {
    font-size: 2rem;
  }
}

@media screen and (min-width: 420px) {
  .main-text-header {
    font-size: 2.25rem;
  }
}

@media screen and (min-width: 592px) {
  :root {
    --footer-height: 3.3rem;
  }
}

@media screen and (min-width: 600px) {
  .main-text-header {
    font-size: 3rem;
  }
}

@media screen and (min-width: 768px) {
  .main-text-header {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1280px) {
  .main-text-header {
    font-size: 7rem;
  }
}
